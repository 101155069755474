<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <h2>Solicitação de demanda</h2>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :rules="assuntoRules"
                    label="Assunto"
                    v-model="data.title"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :rules="[(v) => !!v || 'Classificação obrigatoria']"
                    :items="classificacoes"
                    item-text="name"
                    item-value="id"
                    v-model="data.rank_id"
                    :error-messages="errors.rank_id"
                    label="Classificação"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :rules="corpoRules"
                    filled
                    label="Corpo"
                    v-model="data.body"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="data.files"
                    multiple
                    truncate-length="15"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="sendSolicitacao"> Enviar </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "@/services/ToastService";
import ClassificacaoService from "@/services/Demanda/ClassificacaoService";
import axiosFormData from "@/plugins/axiosFormData";

export default {
  data() {
    return {
      responsaveis: [],
      data: {},
      errors: {},
      classificacoes: [],
      ClassificacaoService: new ClassificacaoService("ranks"),

      assuntoRules: [
        (v) => !!v || "Assunto é obrigatorio",
        (v) =>
          (v && v.length >= 5) || "O assunto deve ter mais de 5 characteres",
      ],
      corpoRules: [
        (v) => !!v || "O corpo é obrigatorio",
        (v) => (v && v.length >= 5) || "O corpo deve ter mais de 5 characteres",
      ],
    };
  },
  computed: {
    ...mapGetters({
      profile: "getPermissions",
      me: "getMe",
    }),
  },
  methods: {
    async sendSolicitacao() {
      try {
        if (this.$refs.form.validate()) {
          let formData = new FormData();

          this.data.files?.forEach((element) => {
            formData.append("files[]", element);
          }) ?? formData.append("files[]", []);
          formData.append("title", this.data.title);
          formData.append("rank_id", this.data.rank_id);
          formData.append("body", this.data.body);

          await axiosFormData.post("requesters", formData);

          ToastService("Solicitação realizada com sucesso.", "success");
          // this.$refs.form.reset();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showClassificacoes() {
      try {
        this.classificacoes = await this.ClassificacaoService.index("");
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");
      }
    },
  },
  async mounted() {
    await this.showClassificacoes();
  },
};
</script>

<style></style>
